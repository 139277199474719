<template>
  <div>
    <v-toolbar flat height="80px">
      <v-toolbar-title class="text-h1">DarkRecon Site Manager</v-toolbar-title>
      <v-spacer />
      <v-btn color="accent" icon text @click="addSite = true">
        <v-icon>mdi-plus-circle</v-icon></v-btn
      >
    </v-toolbar>
    <div class="mt-4" id="DarkReconSiteManager">
      <v-dialog
        v-model="updateSite"
        max-width="300"
        min-height="200"
        persistent
        :overlay-color="'#000000'"
        :overlay-opacity="0.9"
        style="z-index: 202; background: #153059"
      >
        <v-card
          style="background: #153059; padding: 0em 1em 1em 0em"
          max-width="300"
          min-height="200"
        >
          <v-card-title class="text-white pt-6">Confirmation</v-card-title>
          <v-icon class="float-right mt-n8" @click="updateSite = false"
            >mdi-close</v-icon
          >
          <v-card-text style="color: #fff"
            >Are you sure you want to {{ updateSiteText }} this
            site?</v-card-text
          >

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="accent"
              style="min-width: 150px"
              @click="updateSiteSubmit()"
              >SUBMIT</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="addSite"
        max-width="500"
        min-height="500"
        persistent
        :overlay-color="'#000000'"
        :overlay-opacity="0.9"
        style="z-index: 202; background: #153059"
      >
        <v-card
          style="background: #153059; padding: 0em 1em 1em 0em"
          max-width="500"
          min-height="500"
        >
          <v-card-title class="text-white pt-6">Add New Site </v-card-title>
          <v-icon class="float-right mt-n8" @click="addSite = false"
            >mdi-close</v-icon
          >
          <v-card-text style="color: #fff" v-if="submissionSuccessful == false">
            <v-text-field
              v-model="siteName"
              placeholder="Name"
              label="Name"
            ></v-text-field>
            <div class="msgContent" v-if="msgContent.length > 0">
              {{ msgContent }}
            </div>

            <v-text-field
              v-model="siteNameUrl"
              placeholder="URL"
              label="URL"
            ></v-text-field>
            <v-row>
              <v-col>
                <v-switch
                  v-model="siteNameEnabled"
                  :label="siteNameEnabled ? 'Enabled' : 'Disabled'"
                ></v-switch> </v-col
              ><v-col>
                <v-select
                  label="Language"
                  placeholder="Language"
                  name="Language"
                  v-model="siteNameLanguage"
                  :items="siteNameLanguages"
                />
              </v-col>
            </v-row>
            <v-textarea
              label="Description"
              placeholder="Description"
              name="Description"
              v-model="siteNameDescription"
              filled
            ></v-textarea>
            <h3 class="font-weight-light">Credentials (Optional)</h3>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="siteNameUsername"
                  placeholder="Username"
                  label="Username"
                ></v-text-field> </v-col
              ><v-col>
                <v-text-field
                  v-model="siteNamePassword"
                  :append-icon="show1 ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="show1 ? 'text' : 'password'"
                  name="input-10-1"
                  label="Password"
                  @click:append="show1 = !show1"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text style="color: #fff" v-if="submissionSuccessful == true">
            Site submitted successfully</v-card-text
          >
          <v-card-actions v-if="submissionSuccessful == false">
            <v-spacer></v-spacer>
            <v-btn
              class="accent"
              style="min-width: 150px"
              @click="addNewSite()"
              v-if="siteName.length > 0 && siteNameUrl.length > 0"
              >Submit</v-btn
            >
            <v-btn
              color="rgb(88, 171, 231)"
              depressed
              disabled
              v-else
              style="border-radius: 5px !important; min-width: 150px"
            >
              <span style="color: #fff">Submit</span>
            </v-btn>
          </v-card-actions>

          <v-card-actions v-if="submissionSuccessful == true">
            <v-spacer></v-spacer>
            <v-btn
              class="accent"
              style="min-width: 150px"
              @click="addSite = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-row>
        <v-row style="display: contents"
          ><v-col>
            <v-text-field
              v-model="name"
              placeholder="Filter Name"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select
              :items="[
                { name: 'All', value: null },
                { name: 'Enabled', value: true },
                { name: 'Disabled', value: false },
              ]"
              v-model="enabled"
              item-value="value"
              item-text="name"
              @input="updateEnabled(enabled)"
              label="Enabled"
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              :items="[
                { name: 'All', value: null },
                { name: 'Yes', value: true },
                { name: 'No', value: false },
              ]"
              v-model="has_credentials"
              item-value="value"
              item-text="name"
              @input="updateCredentials(has_credentials)"
              label="Credentials"
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              :items="[
                { name: 'Name', value: 'name' },
                { name: 'Date Added', value: 'date_added' },
                { name: 'URL', value: 'url' },
                { name: 'Status', value: 'status' },
                { name: 'Status Date', value: 'status_date' },
              ]"
              v-model="sort_by"
              item-value="value"
              item-text="name"
              @input="updateSortBy(sort_by)"
              label="Sort by"
            ></v-select>
          </v-col>
          <v-col>
            <caption
              style="
                font-size: 0.72em;
                width: 100px;
                margin-left: -2em;
                margin-bottom: 5px;
                color: rgba(255, 255, 255, 0.7);
              "
            >
              Sort order:
            </caption>
            <v-btn-toggle
              v-model="toggleSort"
              mandatory
              style="margin-right: 80px"
            >
              <v-btn small>
                <v-icon>mdi-sort-ascending</v-icon>
              </v-btn>
              <v-btn small>
                <v-icon>mdi-sort-descending</v-icon>
              </v-btn>
            </v-btn-toggle></v-col
          ></v-row
        >
        <v-data-table
          :headers="headers"
          :items="sites"
          :items-per-page="-1"
          disable-sort
          hide-default-footer
        >
          <template v-slot:item="{ item }">
            <tr
              :id="item.id"
              @mouseenter="changeIconColors('enter', item.id)"
              @mouseleave="changeIconColors('exit', item.id)"
            >
              <td>{{ item.name }}</td>
              <td>{{ item.url }}</td>
              <td>
                <v-icon color="primary" class="mr-2" v-if="item.has_credentials"
                  >mdi-check-circle
                </v-icon>
                <v-icon
                  color="#112748"
                  medium
                  class="mr-2"
                  style="
                    color: #112748 !important;
                    caret-color: #112748 !important;
                  "
                  v-if="!item.has_credentials"
                  >mdi-checkbox-blank-circle
                </v-icon>
              </td>
              <td style="cursor: pointer" @click="() => updateItem(item)">
                <v-icon color="primary" class="mr-2" v-if="item.enabled"
                  >mdi-check-circle
                </v-icon>
                <v-icon
                  color="#112748"
                  medium
                  class="mr-2"
                  style="
                    color: #112748 !important;
                    caret-color: #112748 !important;
                  "
                  v-if="!item.enabled"
                  >mdi-checkbox-blank-circle
                </v-icon>
              </td>

              <td>{{ item.created_at }}</td>
              <td>
                <span v-if="item.status">{{
                  item.status.replaceAll("_", " ")
                }}</span>
              </td>
              <td>
                <span v-if="item.status_date !== 'Invalid date'">{{
                  item.status_date
                }}</span>
              </td>
              <td>
                <span
                  style="cursor: pointer"
                  @click="goToSiteCredentials(item.id)"
                >
                  <v-icon color="#153059">mdi-open-in-new</v-icon>
                </span>
              </td>
            </tr>
          </template>
        </v-data-table>
        <div style="min-height: 100px">&nbsp;</div>
        <infinite-loading
          :identifier="infiniteId"
          @infinite="infiniteHandler"
          v-if="sites.length > 19"
        >
          <div slot="spinner">
            <v-row>
              <div v-for="(row, index) in 6" :key="index">
                <v-skeleton-loader
                  class="mx-auto"
                  max-width="960px"
                  width="960px"
                  type="text"
                  style="margin-top: 1em; margin-bottom: 1em"
                  dark
                ></v-skeleton-loader>
                <v-skeleton-loader
                  class="mx-auto"
                  max-width="960px"
                  width="960px"
                  type="divider"
                  style="margin-top: 1em; margin-bottom: 1em"
                  dark
                ></v-skeleton-loader>
              </div>
            </v-row>
          </div>
          <div slot="no-more" class="body-2 mt-2">&nbsp;</div>
          <div slot="no-results" class="body-2 mt-2">&nbsp;</div>
        </infinite-loading>
        <v-fade-transition>
          <v-btn
            v-scroll="onScroll"
            v-show="fab"
            fab
            dark
            fixed
            bottom
            right
            outlined
            color="white"
            @click="toTop"
          >
            <v-icon>mdi-arrow-up-bold</v-icon>
          </v-btn>
        </v-fade-transition>
      </v-row>
    </div>
    <v-snackbar
      v-model="showClipboard"
      color="#58abe7"
      centered
      :timeout="2000"
      elevation="24"
    >
      {{ showMsg }}
    </v-snackbar>
  </div>
</template>
<script>
import { formatDate } from "@/utils/common";
import router from "@/router";
import store from "@/store";
import InfiniteLoading from "vue-infinite-loading";
import SkeletonLoader from "@/components/SkeletonLoader.vue";
export default {
  name: "DarkReconSiteManager",
  components: {
    SkeletonLoader,
    InfiniteLoading,
  },
  data: function () {
    return {
      showClipboard: false,
      showMsg: "",
      updateSiteText: "",
      submissionSuccessful: false,
      updateSite: false,
      siteName: "",
      siteNameUrl: "",
      siteNameEnabled: false,
      siteNameDescription: "",
      siteNameLanguage: "",
      siteNamePassword: null,
      siteNameUsername: null,
      siteNameLanguages: [
        "English",
        "Russian",
        "Danish",
        "German",
        "French",
        "Arabic",
        "Swedish",
        "Dutch",
      ],
      addSite: false,
      show1: false,
      enabled: null,
      sort_order: "asc",
      sort_by: "name",
      has_credentials: null,
      toggleSort: 0,
      infiniteId: 1,
      initialLoad: true,
      msgContent: "",
      alertMsg: false,
      fab: false,
      formatDate: formatDate,
      name: "",
      headers: [
        {
          text: "Name",
          value: "name",
          filterable: false,
        },
        {
          text: "Url",
          value: "url",
          filterable: false,
        },
        {
          text: "Credentials",
          value: "has_credentials",
          filterable: false,
        },
        {
          text: "Enabled",
          value: "enabled",
          filterable: false,
        },
        {
          text: "Date Added",
          value: "created_at",
          filterable: false,
        },
        {
          text: "Status",
          value: "status",
          filterable: false,
        },
        {
          text: "Status Date",
          value: "status_date",
          filterable: false,
        },
        {
          text: "",
          value: "manage",
          filterable: false,
        },
      ],
    };
  },
  mounted() {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    this.updateUrlParams(urlParams);
  },
  methods: {
    goToSiteCredentials(site_id) {
      let routeData = this.$router.resolve({
        path: "/darkrecon/sites/" + site_id,
      });
      window.open(routeData.href, "_blank");
    },
    updateUrlParams(urlParams) {
      for (const [key, value] of urlParams.entries()) {
        const val = urlParams.get(key);

        if (key == "sort_order") {
          store.commit("customerList/replaceValue", {
            key: "sort_order",
            value: val,
          });
          this.sort_order = val;
        }

        if (key == "sort_by") {
          this.updateSortBy(val);
          this.sort_by = val;
        }
        if (key == "enabled") {
          this.updateEnabled(val);
          this.enabled = val;
        }
        if (key == "has_credentials") {
          this.updateCredentials(val);
          this.has_credentials = val;
        }
        if (key == "name") {
          this.name = val;
        }
      }
      this.getSites();
    },
    getSites() {
      store.commit("darkReconSiteManager/replaceValue", {
        key: "page",
        value: 1,
      });
      store.dispatch("darkReconSiteManager/getSites");
      this.infiniteId += 1;
      this.initialLoad = false;
    },
    updateSortBy(value) {
      store.commit("darkReconSiteManager/replaceValue", {
        key: "sort_by",
        value: value,
      });
      if (this.initialLoad == false) {
        this.getSites();
      }
    },
    updateEnabled(value) {
      store.commit("darkReconSiteManager/replaceValue", {
        key: "enabled",
        value: value,
      });
      if (this.initialLoad == false) {
        this.getSites();
      }
    },
    updateCredentials(value) {
      store.commit("darkReconSiteManager/replaceValue", {
        key: "has_credentials",
        value: value,
      });
      if (this.initialLoad == false) {
        this.getSites();
      }
    },
    updateItem(item) {
      console.log(item);
      store.commit("darkReconSiteManager/replaceValue", {
        key: "selectedSite",
        value: item,
      });
      if (item.disabled == true) {
        item.disabled = false;
        this.updateSiteText = "enable";
      } else {
        item.disabled = true;
        this.updateSiteText = "disable";
      }
      this.updateSite = true;
    },
    updateSiteSubmit() {
      store.dispatch("darkReconSiteManager/updateSite").then((result) => {
        console.log(result);
        this.showMsg = result.msg;
        this.showClipboard = true;
        this.updateSite = false;
        this.getSites();
      });
    },
    addNewSite() {
      store.commit("darkReconSiteManager/replaceValue", {
        key: "siteName",
        value: this.siteName,
      });
      store.commit("darkReconSiteManager/replaceValue", {
        key: "siteNameLanguage",
        value: this.siteNameLanguage,
      });
      store.commit("darkReconSiteManager/replaceValue", {
        key: "siteNameDescription",
        value: this.siteNameDescription,
      });
      store.commit("darkReconSiteManager/replaceValue", {
        key: "siteNameEnabled",
        value: this.siteNameEnabled,
      });
      store.commit("darkReconSiteManager/replaceValue", {
        key: "siteNameUrl",
        value: this.siteNameUrl,
      });
      store.commit("darkReconSiteManager/replaceValue", {
        key: "siteNamePassword",
        value: this.siteNamePassword,
      });
      store.commit("darkReconSiteManager/replaceValue", {
        key: "siteNameUsername",
        value: this.siteNameUsername,
      });
      store.dispatch("darkReconSiteManager/addNewSite").then((result) => {
        console.log(result);
        if (result.success == true) {
          this.submissionSuccessful = true;
          this.getSites();
        } else {
          this.msgContent = result.message;
          this.alertMsg = true;
          setTimeout(() => {
            this.msgContent = "";
            this.alertMsg = false;
          }, 2500);
        }
      });
    },
    changeIconColors(hover, id) {
      document.getElementById(id).classList.remove("hovering");

      if (hover == "enter") {
        document.getElementById(id).classList.add("hovering");
      }
      if (hover == "exit") {
        document.getElementById(id).classList.remove("hovering");
      }
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 100;
    },
    toTop: function () {
      this.$vuetify.goTo(0);
    },
    infiniteHandler($state) {
      store.commit("darkReconSiteManager/nextPage");
      store.dispatch("darkReconSiteManager/getSites").then((result) => {
        if (result === null) {
          $state.loaded();
          return;
        }
        if (result.length > 0) {
          $state.loaded();
          return;
        }
        if (result.length === 0) {
          $state.complete();
          return;
        }
      });
    },
  },
  computed: {
    sites: () => {
      const sites = store.state.darkReconSiteManager.sites || [];
      return sites;
    },
  },
  watch: {
    addSite: {
      handler: function (val) {
        if (val == false) {
          this.msgContent = "";
          this.siteName = "";
          this.siteNameEnabled = false;
          this.submissionSuccessful = false;
          this.siteNameUrl = "";
          this.siteNamePassword = null;
          this.siteNameUsername = null;
        }
      },
    },
    name: {
      handler: function (val) {
        if (val.length < 1) {
          store.commit("darkReconSiteManager/replaceValue", {
            key: "name",
            value: "",
          });
        }
        if (val.length >= 1) {
          store.commit("darkReconSiteManager/replaceValue", {
            key: "name",
            value: val,
          });
        }
        if (this.initialLoad == false) {
          this.getSites();
        }
      },
    },
    toggleSort: {
      handler: function (val) {
        if (val == 0) {
          this.sort_order = "asc";
        }
        if (val == 1) {
          this.sort_order = "desc";
        }

        store.commit("darkReconSiteManager/replaceValue", {
          key: "sort_order",
          value: this.sort_order,
        });
        if (this.initialLoad == false) {
          this.getSites();
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style scoped>
#DarkReconSiteManager .v-data-table {
  width: 100%;
}
#DarkReconSiteManager >>> th,
#DarkReconSiteManager >>> td {
  white-space: nowrap;
}
#DarkReconSiteManager >>> td:nth-child(2) {
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 150px;
  max-width: 150px;
}

#DarkReconSiteManager >>> td:nth-child(1) {
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 150px;
  max-width: 150px;
}

#DarkReconSiteManager >>> td:nth-child(6) {
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 150px;
  max-width: 150px;
}

.msgContent {
  padding: 0.5em;
  background-color: #112748;
  color: #e86051;
  font-size: 0.9em;
  margin-top: -1.5em;
  border-radius: 4px;
}
.hovering .v-icon {
  color: #112748 !important;
}
</style>
